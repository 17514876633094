<template>
  <div>
    <AdWrapper :ad="{ slotId: 'native_impression_tracking' }" />
    <WidthWrapper>
      <main id="advertorial-id" class="my-40">
        <div class="px-20 lg:w-700">
          <div class="text-grey-450 mb-20 flex flex-wrap items-center">
            <div class="uppercase font-semibold text-14 pr-10">{{ apiData.data.attributes.categoryName }}</div>
            <Date v-if="apiData.data.attributes.published" class="text-14" :date="apiData.data.attributes.published" :updated-date="apiData.data.attributes.displayUpdatedTime ? apiData.data.attributes.changed : undefined" />
          </div>
          <PageTitle :title="title" />
          <div class="text-20 leading-26 md:leading-26 font-bold mb-20" v-if="apiData.data.attributes.teaser">{{ apiData.data.attributes.teaser }}</div>
          <div class="font-normal text-14 text-black mb-20" v-if="apiData.data.attributes.byline">{{ apiData.data.attributes.byline }}</div>
        </div>
        <MainMedia :media="apiData.data.attributes.media" :disabled="apiData.data.attributes.disableMainMedia" :page-type="apiData.data.type" :description="apiData.data.attributes.mediaDescription" :alt="apiData.data.attributes.mediaAlt" />
      </main>
      <div v-if="apiData.data.attributes.customerLogo" class="flex justify-center items-start mb-30 lg:mb-50">
        <a v-if="apiData.data.attributes.customerLogoLink" :href="apiData.data.attributes.customerLogoLink" rel="noopener noreferrer sponsored" target="_blank">
          <AllerImgixImg v-if="apiData.data.attributes.customerLogo.imageUrl" :media="apiData.data.attributes.customerLogo" sizes="100px" width="100" height="100" loading="lazy" class="bg-transparent max-w-[100px]" :modifiers="{ fit: 'min' }" :render-widths="[100, 200]" />
        </a>
        <AllerImgixImg v-else :media="apiData.data.attributes.customerLogo" sizes="100px" width="100" height="100" loading="lazy" class="bg-transparent max-w-[100px]" :modifiers="{ fit: 'min' }" :render-widths="[100, 200]" />
      </div>
      <div class="lg:w-565 px-20 lg:px-30 mx-auto">
        <ContentComponents :content-components="apiData.data.attributes.contentComponents" class="relative" />
      </div>
    </WidthWrapper>
  </div>
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.AdvertorialArticle;

/**
 *
 * Decides if title has prefix in it or not
 *
 * @returns String
 */
const title = computed((): string => {
  return apiData.data.attributes.titlePrefix ? `${apiData.data.attributes.titlePrefix} ${apiData.data.attributes.title}` : apiData.data.attributes.title;
});

/**
 * Inject metatags (OG, Schema.org)
 */
const { articleMeta } = useAllerMetaData();
articleMeta(apiData.data, 'AdvertiserContentArticle');
</script>

<style>
#advertorial-id [data-aller-affiliated-link]:after {
  display: none;
}
</style>
